.popover {
    z-index: 2;
    position: absolute;

    .click-away {
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        position: fixed;
    }
}

.color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
}

.swatch-label {
    display: inline-block;
    vertical-align: middle;
}

.swatch {
    padding: 5px;
    cursor: pointer;
    margin-right: 1rem;
    background: #fff;
    border-radius: 1px;
    display: inline-block;
    vertical-align: middle;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
